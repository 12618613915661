<!-- @format -->

<template>
  <b-card title="品牌编辑">
    <validation-observer ref="brandEdit">
      <b-form>
        <b-row>
          <b-col md="4">
            <b-form-group label-align="center" label="分类名称" label-cols="1" label-cols-md="3" content-cols="6" content-cols-md="8">
              <validation-provider #default="{ errors }" name="分类名称" rules="required">
                <b-form-input v-model="editInfo.title" :state="errors.length > 0 ? false : null" placeholder="输入分类名称" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-align="center" label="分类状态" label-cols="1" label-cols-md="3" content-cols="6" content-cols-md="8">
              <b-form-radio-group
                v-model="editInfo.status"
                :options="[
                  {
                    text: '启用',
                    value: 1,
                  },
                  {
                    text: '禁用',
                    value: 0,
                  },
                ]"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button variant="primary" type="submit" @click.prevent="validationForm" :disabled="isRequest"> 提交 </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { computed, ref, getCurrentInstance } from '@vue/composition-api'
import { required, url } from '@core/utils/validations/validations'

import store from '@/store'

import BrandManagerModule from '@/views/GeneralManagement/ProductManager/Brand/BrandManagerModule'
import { BButton, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BRow, BCard } from 'bootstrap-vue'

import { useRouter, throttle } from '@core/utils/utils'

export default {
  name: 'BrandEdit',
  components: {
    BFormRadioGroup,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
  },

  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'classification-module'

    const { proxy } = getCurrentInstance()

    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, BrandManagerModule)

    const editInfo = computed(() => store.state[INVOICE_APP_STORE_MODULE_NAME].classDetail)

    const { route, router } = useRouter()
    const isRequest = ref(false)

    const brandEdit = ref(null)

    const validationForm = throttle(() => {
      brandEdit.value.validate().then(success => {
        if (success) {
          isRequest.value = true

          delete editInfo.value?.created_at

          store
            .dispatch('classification-module/editInvoices', { ...editInfo.value })
            .then(response => {
              if (response.code == 0) {
                proxy.$bvToast.toast(response.msg || '修改成功', {
                  title: '提示',
                  variant: 'success',
                  solid: true,
                  autoHideDelay: 2000,
                })
                const timer = setTimeout(() => {
                  isRequest.value = false
                  clearTimeout(timer)
                  router.back()
                }, 500)
              } else {
                proxy.$bvToast.toast(response.msg || '修改失败', {
                  title: '提示',
                  variant: 'danger',
                  solid: true,
                  autoHideDelay: 2000,
                })
              }
            })
            .catch(() => {
              isRequest.value = false
            })
        } else {
          isRequest.value = false
        }
      })
    }, 0)

    return {
      validationForm,

      isRequest,

      required,
      url,

      brandEdit,
      editInfo,
    }
  },
}
</script>
